import React from 'react';
import './property info.css'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message, Spin, TreeSelect, Image, Popconfirm } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import Tooltip from '@material-ui/core/Tooltip';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class propertyInfo extends React.Component {
  state = {
    actionType: this.props.location.state.actionType ? this.props.location.state.actionType === "EDIT" ? "EDIT" : "VIEW" : "VIEW",
    id: this.props.location.state.data.id,
    finishingType: this.props.location.state.data.finishingType ? this.props.location.state.data.finishingType : ['FULLY-FINISHED'],
    deliveryStatus: this.props.location.state.data.deliveryStatus ? this.props.location.state.data.deliveryStatus : ['READY-TO-MOVE'],
    priceType: this.props.location.state.data.priceType ? this.props.location.state.data.priceType : 'INSTALLMENT',
    pricePerMeter: this.props.location.state.data.pricePerMeter ? this.props.location.state.data.pricePerMeter : 0,
    price: this.props.location.state.data.price ? this.props.location.state.data.price : 0,
    downPayment: this.props.location.state.data.downPayment ? this.props.location.state.data.downPayment : 0,
    durationType: this.props.location.state.data.durationType ? this.props.location.state.data.durationType : 'YEARLY',
    installmentDuration: this.props.location.state.data.installmentDuration ? this.props.location.state.data.installmentDuration : 0,
    project: this.props.location.state.data.project ? this.props.location.state.data.project.id : null,
    category: this.props.location.state.data.category ? this.props.location.state.data.category.id : null,
    location: this.props.location.state.data.location ? this.props.location.state.data.location.id : null,
    top: this.props.location.state.data.top ? this.props.location.state.data.top : false,
    type: this.props.location.state.data.type,
    categories: [],
    locations: [],
    projects: [],
    imgs: this.props.location.state.data.imgs ? this.props.location.state.data.imgs : null,
    allImgs: this.props.location.state.data.imgs ? this.props.location.state.data.imgs : [],
    imgsPreviewUrl: this.props.location.state.data.imgs ? this.props.location.state.data.imgs : [],
    imgsName: [],
    imgsType: 'url',
    pdf: this.props.location.state.data.pdf ? this.props.location.state.data.pdf : null,
    pdfType: "url",
    pdfName: [],
    voiceNote: this.props.location.state.data.voiceNote ? this.props.location.state.data.voiceNote : null,
    voiceNoteType: "url",
    loading: false,
    refresh: false,
    available: this.props.location.state.data.available

  };

  constructor(props) {
    super(props)
    window.scrollTo(0, 0)
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
  }
  componentDidMount() {
    this.getData()
    this.getCategory()
    this.getLocations()
    this.getProjects(this.state.type)
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
  }
  getData = () => {
    axios.get(`${BASE_END_POINT}properties/${this.state.id}?forMobile=false`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language': this.props.isRTL ? 'ar' : 'en'
      }
    })
      .then(response => {
        console.log('theUser  ', response.data.data)
        this.setState({
          loading: false,
          finishingType: response.data.data.finishingType ? response.data.data.finishingType : ['FULLY-FINISHED'],
          deliveryStatus: response.data.data.deliveryStatus ? response.data.data.deliveryStatus : ['READY-TO-MOVE'],
          priceType: response.data.data.priceType ? response.data.data.priceType : 'INSTALLMENT',
          pricePerMeter: response.data.data.pricePerMeter ? response.data.data.pricePerMeter : 0,
          type: response.data.data.type,
          price: response.data.data.price ? response.data.data.price : 0,
          downPayment: response.data.data.downPayment ? response.data.data.downPayment : 0,
          durationType: response.data.data.durationType ? response.data.data.durationType : 'YEARLY',
          installmentDuration: response.data.data.installmentDuration,
          project: response.data.data.project ? response.data.data.project.id : null,
          category: response.data.data.category ? response.data.data.category.id : null,
          location: response.data.data.location ? response.data.data.location.id : null,
          imgs: response.data.data.imgs ? response.data.data.imgs : null,
          allImgs: response.data.data.imgs ? response.data.data.imgs : null,
          imgsPreviewUrl: response.data.data.imgs ? response.data.data.imgs : [],
          pdf: response.data.data.pdf ? response.data.data.pdf : null,
          voiceNote: response.data.data.voiceNote ? response.data.data.voiceNote : null,
          top: response.data.data.top ? response.data.data.top : false,
        })
      })
      .catch(error => {
        console.log('theUser ERROR  ', error)
        console.log('theUser ERROR  ', error.respose)
      })
  }
  getCategory = () => {
    axios.get(`${BASE_END_POINT}categories`)
      .then(response => {
        console.log(response.data)
        this.setState({ categories: response.data.data })
      })
      .catch(error => {
        console.log("ALL categories ERROR")
        console.log(error.response)
      })
  }
  getProjects = (type) => {
    if (type != 'SEPARATED') type = 'COMPOUND'
    axios.get(`${BASE_END_POINT}projects/withoutPagenation/get?type=${type}`)
      .then(response => {
        console.log(response.data)
        this.setState({ projects: response.data.data })
      })
      .catch(error => {
        console.log("ALL projects ERROR")
        console.log(error.response)
      })
  }
  getLocations = () => {
    axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
      .then(response => {
        console.log(response.data)
        this.setState({ locations: response.data.data })
      })
      .catch(error => {
        console.log("ALL locations ERROR")
        console.log(error.response)
      })
  }
  updateProperty = () => {
    const { type, finishingType, deliveryStatus, location, category, imgs, imgsType, pdf, pdfType,
      project, price, priceType, pricePerMeter, durationType, installmentDuration, downPayment, voiceNote, voiceNoteType } = this.state

    if (category !== null && location !== null && project !== null) {
      let l = message.loading('Wait..', 2.5)
      var data = new FormData()
      data.append('finishingType', JSON.stringify(finishingType))
      data.append('deliveryStatus', JSON.stringify(deliveryStatus))
      data.append('project', project)
      data.append('price', price)
      data.append('priceType', priceType)
      data.append('category', category)
      data.append('location', location)
      data.append('pricePerMeter', price)
      data.append('type', type)

      if (priceType === 'INSTALLMENT') {
        data.append('downPayment', downPayment)
        data.append('durationType', durationType)
        data.append('installmentDuration', installmentDuration)
      }

      if (imgs != null && imgsType === "data") {
        for (var i = 0; i <= imgs.length - 1; i++) {
          data.append(`imgs`, imgs[i])
        }
      }
      if (pdf != null && pdfType === "data") {
        data.append(`pdf`, pdf[0])
      }
      if (voiceNote != null && voiceNoteType === "data") {
        data.append(`voiceNote`, voiceNote)
      }
      if (voiceNote == null) {
        data.append(`emptyVoiceNote`, true)
      }

      console.log(Array.from(data))
      axios.put(`${BASE_END_POINT}properties/${this.props.location.state.data.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language': this.props.isRTL ? 'ar' : 'en'
        },
      })
        .then(response => {
          console.log("USER UPDATED  ", response.data)
          l.then(() => message.success(allStrings.done, 2.5))
          this.props.history.goBack()
          // this.setState({actionType:'VIEW'})
          // this.getData()
          // window.scrollTo(0, 0)
        })
        .catch(error => {
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }

  delete = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}properties/${this.props.location.state.data.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language': this.props.isRTL ? 'ar' : 'en'
      },
    })
      .then(response => {
        console.log("Row DELETED")
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
      })
      .catch(error => {
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  fileChangedHandler = (event) => {
    let imgs = [];
    let imgsPreviewUrl = [];
    let imgsName = []
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      imgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
      imgsName.push(event.target.files[i].name)
    }
    imgs.push(event.target.files)
    this.setState({
      imgs: event.target.files,
      imgsType: 'data',
      imgsPreviewUrl: imgsPreviewUrl,
      imgsName: imgsName
    });

  };
  removeFile = (url) => {
    let arr = this.state.imgsPreviewUrl;
    let arr2 = Array.from(this.state.imgs);
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    this.setState({
      imgsPreviewUrl: arr,
      imgs: arr2,
    });
    if (arr2.length === 0) {
      this.setState({
        imgs: null,
      });
    }

  };
  fileChangedHandlerPdf = event => {
    console.log(event.target.files[0])
    let pdfName = []
    pdfName.push(event.target.files[0].name)
    let pdf = [];
    pdf.push(event.target.files[0])
    this.setState({
      pdf: pdf,
      pdfType: "data",
      pdfName: pdfName
    })
    console.log(this.state.pdf)

  }
  removeFilePdf = () => {
    this.setState({
      pdf: null,
      pdfType: "url",
      pdfName: []
    });
  }
  fileChangedHandlerVoiceNote = event => {
    console.log(event.target.files[0])
    this.setState({
      voiceNote: event.target.files[0],
      voiceNoteType: "data"
    })
    document.addEventListener("DOMContentLoaded", function () {
      document.getElementById('audioFileInput').addEventListener('change', function (event) {
        var file = event.target.files[0];
        var audioPlayer = document.getElementById('audioPlayer');
        var audioSource = document.getElementById('audioSource');
        if (file) {
          audioSource.src = URL.createObjectURL(file);
          audioPlayer.load();
        }
      });
    });
  }
  removeFileVoiceNote = () => {
    this.setState({
      voiceNote: null,
      voiceNoteType: "url"
    });
  }

  imageSection = () => (
    <MDBCol xl="4" lg="5" md="12" sm="12">
      <div className="infoCard" style={{ marginTop: '80px' }}>
        <div className="StoreSlider">
          <div style={{ height: '280px', overflow: 'hidden' }}>
            <Image.PreviewGroup >
              {this.state.allImgs.map((val, i) =>
                <Image placeholder={true} width="100%" height="100%" style={{ display: i === 0 ? "block" : "none", borderRadius: '6px' }} src={val} />
              )}
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
    </MDBCol>
  )



  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateProperty()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  available = (available) => {
    let uri = '';
    if (available) {
      uri = `${BASE_END_POINT}properties/${this.state.id}/available`
    } else {
      uri = `${BASE_END_POINT}properties/${this.state.id}/dis-available`
    }
    let l = message.loading('Wait..', 2.5)
    axios.put(uri, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language': this.props.isRTL ? 'ar' : 'en'
      },
    })
      .then(response => {
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
      })
      .catch(error => {
        console.log('Error   ', error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
  }
  top = (top) => {
    let uri = '';
    if (top) {
      uri = `${BASE_END_POINT}properties/${this.state.id}/addToTop`
    } else {
      uri = `${BASE_END_POINT}properties/${this.state.id}/removeFromTop`
    }
    let l = message.loading('Wait..', 2.5)
    axios.put(uri, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language': this.props.isRTL ? 'ar' : 'en'
      },
    })
      .then(response => {
        l.then(() => message.success(allStrings.done, 2.5))
        this.props.history.goBack()
      })
      .catch(error => {
        console.log('Error   ', error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
  }


  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PROPERTIES')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{ color: '#233b5b' }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl={this.state.actionType === "VIEW" ? "8" : "12"} lg={this.state.actionType === "VIEW" ? "7" : "12"} md="12" sm="12">
                <div className="infoCard">
                  <Card className="editCard">
                    <CardMedia
                      style={{ height: "0px" }}
                    />
                    <CardHeader></CardHeader>
                    <div style={{ flexDirection: this.props.isRTL ? 'row-reverse' : 'row', display: 'flex' }}>
                      <div className="topIcon">
                        <span className="material-icons" style={{ color: 'white', fontSize: 23 }}>location_city</span>
                      </div>
                      <div className="tableTitle" style={{
                        display: this.props.isRTL ? 'inline-block' : 'inline-block', marginTop: this.props.isRTL ? '-60px' : '-60px',
                        marginLeft: this.props.isRTL ? '0' : '0', marginRight: this.props.isRTL ? '0' : '0', position: 'relative'
                      }}>
                        <p>{this.state.actionType === "VIEW" ? allStrings.info : allStrings.edit}</p>
                      </div>
                    </div>
                    <CardContent style={{ minHeight: "390px", outline: 'none' }}>
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="12">
                            <form
                              className="needs-validation"
                              onSubmit={this.submitHandler}
                              noValidate
                            >

                              <div className="grey-text">
                                {this.state.actionType === "VIEW" &&
                                  <MDBRow style={{ flexDirection: this.props.isRTL ? 'row-reverse' : 'row' }}>
                                    <MDBCol md="12">
                                      <div className="md-form">
                                        <label htmlFor="form8" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>#</label>
                                        <input disabled style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                }
                                {this.state.actionType === "VIEW" &&
                                  <MDBRow style={{ flexDirection: this.props.isRTL ? 'row-reverse' : 'row' }}>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form08" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.showTop}</label>
                                        <input disabled style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.top ? allStrings.yes : allStrings.no} type="text" id="form08" className="form-control" />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form88" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.showInApp}</label>
                                        <input disabled style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.available ? allStrings.yes : allStrings.no} type="text" id="form88" className="form-control" />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                }
                                <MDBRow style={{ flexDirection: this.props.isRTL ? 'row-reverse' : 'row' }}>

                                  <MDBCol md="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.type}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={false}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={false}
                                      treeNodeFilterProp="title"
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.type}
                                      value={this.state.type}
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ type: value });
                                        this.getProjects(value)
                                      }}

                                    >
                                      <TreeNode value="RELATED-TO-COMPOUND" title={allStrings.relatedToCompound} key="RELATED-TO-COMPOUND" />
                                      <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />

                                    </TreeSelect>
                                  </MDBCol>
                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.project}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={true}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={false}
                                      treeNodeFilterProp="title"
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.project}
                                      value={this.state.project}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ project: value });
                                      }}

                                    >
                                      {this.state.projects.map(val => (
                                        <TreeNode value={val.id} title={this.props.isRTL ? val.name_ar : val.name_en} key={val.id} />
                                      ))}
                                    </TreeSelect>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  {/* <MDBCol md="6">
                                    <div className="md-form">
                                      <label htmlFor="form33" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.pricePerMeter}</label>
                                      <input disabled={this.state.actionType === "VIEW" ? true : false} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.pricePerMeter} type="number" min={0} id="form33" name="pricePerMeter" className="form-control" onChange={this.changeHandler} required validate />
                                      <div class="valid-feedback">{allStrings.correct}</div>
                                      <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    </div>
                                  </MDBCol> */}
                                  <MDBCol md="12">
                                    <div className="md-form">
                                      <label htmlFor="form34" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.price}</label>
                                      <input disabled={this.state.actionType === "VIEW" ? true : false} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.price} type="number" min={0} id="form34" name="price" className="form-control" onChange={this.changeHandler} required validate />
                                      <div class="valid-feedback">{allStrings.correct}</div>
                                      <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow style={{ flexDirection: this.props.isRTL ? 'row' : 'row-reverse' }}>
                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.priceType}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={false}
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={false}
                                      treeNodeFilterProp="title"
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.priceType}
                                      value={this.state.priceType}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ priceType: value });
                                      }}

                                    >
                                      <TreeNode value="INSTALLMENT" title={allStrings.installment} key="INSTALLMENT" />
                                      <TreeNode value="CASH" title={allStrings.cash} key="CASH" />

                                    </TreeSelect>
                                  </MDBCol>
                                  {this.state.priceType === "INSTALLMENT" &&
                                    <MDBCol md="6" sm="6">
                                      <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.durationType}</label>
                                      <TreeSelect virtual={false}
                                        showSearch={true}
                                        style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                        treeCheckable={false}
                                        treeNodeFilterProp="title"
                                        disabled={this.state.actionType === "EDIT" ? false : true}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={allStrings.durationType}
                                        value={this.state.durationType}
                                        showCheckedStrategy={SHOW_PARENT}
                                        onChange={(value) => {
                                          console.log(value)
                                          this.setState({ durationType: value });
                                        }}

                                      >
                                        <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                        <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />

                                      </TreeSelect>
                                    </MDBCol>
                                  }
                                </MDBRow>
                                {this.state.priceType === "INSTALLMENT" &&
                                  <MDBRow>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form323" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.downPayment}</label>
                                        <input disabled={this.state.actionType === "VIEW" ? true : false} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.downPayment} type="text" id="form323" name="downPayment" className="form-control" onChange={this.changeHandler} required validate />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                    <MDBCol md="6">
                                      <div className="md-form">
                                        <label htmlFor="form324" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.installmentDuration}</label>
                                        <input disabled={this.state.actionType === "VIEW" ? true : false} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr', backgroundPosition: this.props.isRTL ? 'left calc(.375em + .1875rem) center' : 'right calc(.375em + .1875rem) center' }} defaultValue={this.state.installmentDuration} type="number" min={0} id="form324" name="installmentDuration" className="form-control" onChange={this.changeHandler} required validate />
                                        <div class="valid-feedback">{allStrings.correct}</div>
                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                      </div>
                                    </MDBCol>
                                  </MDBRow>
                                }
                                <MDBRow>
                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.location}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={true}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={false}
                                      treeNodeFilterProp="title"
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.location}
                                      value={this.state.location}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ location: value });
                                      }}

                                    >
                                      {this.state.locations.map(val => (
                                        <TreeNode value={val.id} title={this.props.isRTL ? val.name_ar : val.name_en} key={val.id} />
                                      ))}
                                    </TreeSelect>
                                  </MDBCol>
                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.category}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={false}
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={false}
                                      treeNodeFilterProp="title"
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.category}
                                      value={this.state.category}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ category: value });
                                      }}

                                    >
                                      {this.state.categories.map(val => (
                                        <TreeNode value={val.id} title={this.props.isRTL ? val.categoryName_ar : val.categoryName_en} key={val.id} />
                                      ))}
                                    </TreeSelect>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow style={{ flexDirection: this.props.isRTL ? 'row-reverse' : 'row' }}>

                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.deliveryStatus}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={false}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={true}
                                      treeNodeFilterProp="title"
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.deliveryStatus}
                                      value={this.state.deliveryStatus}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ deliveryStatus: value });
                                      }}

                                    >
                                      <TreeNode value="READY-To-MOVE" title={allStrings.readyToMove} key="READY-To-MOVE" />
                                      <TreeNode value="AFTER-ONE-YEAR" title={allStrings.afterOneYears} key="AFTER-ONE-YEAR" />
                                      <TreeNode value="AFTER-TWO-YEARS" title={allStrings.afterTwoYears} key="AFTER-TWO-YEARS" />
                                      <TreeNode value="AFTER-THREE-YEARS" title={allStrings.afterThreeYears} key="AFTER-THREE-YEARS" />

                                    </TreeSelect>
                                  </MDBCol>
                                  <MDBCol md="6" sm="6">
                                    <label style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.finishingType}</label>
                                    <TreeSelect virtual={false}
                                      showSearch={false}
                                      style={{ width: '100%', textAlign: this.props.isRTL ? 'right' : 'left', height: '35px', marginBottom: '1rem' }}
                                      treeCheckable={true}
                                      treeNodeFilterProp="title"
                                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                      placeholder={allStrings.finishingType}
                                      value={this.state.finishingType}
                                      disabled={this.state.actionType === "EDIT" ? false : true}
                                      showCheckedStrategy={SHOW_PARENT}
                                      onChange={(value) => {
                                        console.log(value)
                                        this.setState({ finishingType: value });
                                      }}
                                    >
                                      <TreeNode value="FULLY-FINISHED" title={allStrings.fullyFinished} key="FULLY-FINISHED" />
                                      <TreeNode value="SEMI-FINISHED" title={allStrings.semiFinished} key="SEMI-FINISHED" />
                                      <TreeNode value="CORE-SHELL" title={allStrings.coreShell} key="CORE-SHELL" />

                                    </TreeSelect>
                                  </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                  {this.state.actionType === "EDIT" &&
                                    <MDBCol md="12">
                                      <label for="form830" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.files}</label>
                                      <div className="fileUpload2">
                                        <input key={this.state.pdf} type='file' className="upImgs2" onChange={this.fileChangedHandlerPdf} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{ color: '#000', fontSize: '14px', position: 'relative', top: '-3px', marginRight: '2px' }} /><span className="uploadName">{allStrings.uploadFile}</span></span>
                                      </div>
                                      <div className="preview">
                                        {this.state.pdfName.map((val) => (
                                          <div style={{ display: "inline-block" }}>
                                            <p className="previewPdf">{val}
                                              <MDBIcon icon="times" className="mr-2 removePdf"
                                                onClick={() => this.removeFilePdf()}  ></MDBIcon>
                                            </p>
                                          </div>
                                        ))}

                                      </div>
                                    </MDBCol>
                                  }
                                  <br></br>
                                  {this.state.pdf != null &&
                                    <div>
                                      <a href={this.state.pdf} target="blank" style={{
                                        background: '#233b5b',
                                        color: '#fff',
                                        padding: '3px 40px',
                                        marginLeft: '15px'
                                      }}
                                      >
                                        {allStrings.openFile}
                                      </a>
                                    </div>
                                  }
                                  <br></br>
                                  {this.state.actionType === "EDIT" &&
                                    <MDBCol md="12">
                                      <label for="form830" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.voiceNote}</label>
                                      <div className="fileUpload2">
                                        <input key={this.state.voiceNote} type='file' className="upImgs2" onChange={this.fileChangedHandlerVoiceNote} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{ color: '#000', fontSize: '14px', position: 'relative', top: '-3px', marginRight: '2px' }} /><span className="uploadName">{allStrings.uploadFile}</span></span>
                                      </div>
                                      {this.state.voiceNote != null &&
                                        <div className="preview">
                                          <audio controls className="audioPlayer" id="audioPlayer">
                                            <source id="audioSource" src={this.state.voiceNoteType === "data" ? URL.createObjectURL(this.state.voiceNote) : this.state.voiceNote} type="audio/mpeg" />
                                            <source src={this.state.voiceNoteType === "data" ? URL.createObjectURL(this.state.voiceNote) : this.state.voiceNote} type="audio/ogg"></source>
                                            Your browser does not support the audio element.
                                          </audio>
                                          <MDBIcon icon="trash" className="mr-2 removeImg2" style={{ top: '20px' }}
                                            onClick={() => this.removeFileVoiceNote()}></MDBIcon>
                                        </div>
                                      }
                                    </MDBCol>
                                  }
                                  <br></br>
                                  {this.state.voiceNote != null && this.state.actionType === "VIEW" &&
                                    <MDBCol md="12">
                                      <div style={{ width: '100%' }}>
                                        <label for="form830" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.voiceNote}</label>

                                        <audio controls className="audioPlayerView">
                                          <source src={this.state.voiceNote} type="audio/mpeg" />
                                          <source src={this.state.voiceNote} type="audio/ogg"></source>
                                          Your browser does not support the audio element.
                                        </audio>
                                      </div>
                                    </MDBCol>
                                  }
                                </MDBRow>

                                <br></br>
                                {this.state.actionType === "EDIT" &&
                                  <MDBRow>
                                    <MDBCol md="12">
                                      <label htmlFor="form8930" style={{ textAlign: this.props.isRTL ? 'right' : 'left', width: '100%' }}>{allStrings.imgs}</label>

                                      <div className="fileUpload2">
                                        <input multiple key={this.state.imgs} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{ color: '#000', fontSize: '14px', position: 'relative', top: '-3px', marginRight: '2px' }} /><span className="uploadName">{allStrings.upload}</span></span>
                                      </div>
                                      <div className="preview">
                                        {this.state.imgsPreviewUrl.map((val, i) => (
                                          <div style={{ display: "inline-block", width: '100%', border: '1px solid mediumaquamarine', marginBottom: '10px' }}>
                                            <img crossorigin="anonymous" alt="" src={this.state.imgsType === "data" ? URL.createObjectURL(this.state.imgs[i]) : this.state.imgs[i]} className="previewImg2" />
                                            <span className="imgName">{this.state.imgsName[i]}</span>
                                            <MDBIcon icon="trash" className="mr-2 removeImg2"
                                              onClick={() => this.removeFile(val)}  ></MDBIcon>
                                          </div>
                                        ))}
                                      </div>

                                    </MDBCol>
                                  </MDBRow>
                                }
                              </div>
                              <br></br>
                              <br></br>
                              <div className="text-center">
                                {this.state.actionType === "EDIT" &&
                                  <MDBBtn
                                    style={{ background: '#233b5b', color: '#fff' }}
                                    rounded
                                    type="submit"
                                    className="btn-block z-depth-1a"
                                  >
                                    <MDBIcon icon="edit" className="mr-2" />
                                    {allStrings.update}
                                  </MDBBtn>
                                }{this.state.actionType === "VIEW" &&
                                  <Tooltip title={allStrings.areYouSureDelete} placement="top" arrow>
                                    <Popconfirm okText={allStrings.yes} cancelText={allStrings.no}
                                      onConfirm={() => {
                                        this.delete()
                                      }}>
                                      <MDBBtn
                                        color="red"
                                        rounded
                                        style={{ borderRadius: "2px", background: 'red' }}
                                        className="btn-block z-depth-1a"
                                      >

                                        <MDBIcon icon="trash-alt" className="mr-2" />
                                        {allStrings.remove}
                                      </MDBBtn>
                                    </Popconfirm>
                                  </Tooltip>
                                }
                                {this.state.actionType === "VIEW" && this.state.available &&
                                  <MDBBtn
                                    onClick={() => { this.available(false) }}
                                    rounded
                                    color="warning"
                                    className="btn-block z-depth-1a">
                                    {allStrings.hideInApp}
                                  </MDBBtn>
                                }
                                {this.state.actionType === "VIEW" && !this.state.available &&
                                  <MDBBtn
                                    onClick={() => { this.available(true) }}
                                    rounded
                                    color="info"
                                    className="btn-block z-depth-1a">
                                    {allStrings.showInApp}
                                  </MDBBtn>
                                }
                                {this.state.actionType === "VIEW" && this.state.top &&
                                  <MDBBtn
                                    onClick={() => { this.top(false) }}
                                    rounded
                                    color="warning"
                                    className="btn-block z-depth-1a">
                                    {allStrings.showNormal}
                                  </MDBBtn>
                                }
                                {this.state.actionType === "VIEW" && !this.state.top &&
                                  <MDBBtn
                                    onClick={() => { this.top(true) }}
                                    rounded
                                    color="info"
                                    className="btn-block z-depth-1a">
                                    {allStrings.showTop}
                                  </MDBBtn>
                                }

                              </div>
                            </form>
                          </MDBCol>

                        </MDBRow>
                      </MDBContainer>
                    </CardContent>
                  </Card>
                </div>
              </MDBCol>
              {this.state.actionType === "VIEW" && this.imageSection()}
            </MDBRow>

          </MDBContainer>
        </Spin>
      </div>
    )
  }
}



const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(propertyInfo)
);