import React from 'react';
import './add property.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { message,TreeSelect,Progress } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ResultDialog from "../../component/result/request"
const TreeNode = TreeSelect
const { SHOW_PARENT } = TreeSelect;

class Addproperty extends React.Component {
    state = {
        type:'RELATED-TO-COMPOUND',
        priceType:'INSTALLMENT',
        pricePerMeter:0,
        price:0,
        downPayment:0,
        durationType:'YEARLY',
        installmentDuration:0,
        project:null,
        category:null,
        location:null,
        categories:[],
        locations:[],
        imgs:null,
        allImgs:null,
        imgsPreviewUrl:[],
        imgsName:[],
        imgsType:'data',
        pdf:null,
        pdfType:"data",
        pdfName:[],
        voiceNote:null,
        voiceNoteType:"data",
        progress:80,
        waitResponse:false,
        messageType:'success',
        showDialog:false,
        details:'',
        projects:[],
        finishingType:['FULLY-FINISHED'],
        deliveryStatus:['READY-To-MOVE']
    };

    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getCategory()
        this.getLocations()
        this.getProjects(this.state.type)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    getCategory = () => {
        axios.get(`${BASE_END_POINT}categories`)
        .then(response=>{
          console.log(response.data)
          this.setState({categories:response.data.data})
        })
        .catch(error=>{
          console.log("ALL categories ERROR")
          console.log(error.response)
        })
    }
    getProjects = (type) => {
        if(type != 'SEPARATED') type = 'COMPOUND'
        axios.get(`${BASE_END_POINT}projects/withoutPagenation/get?type=${type}`)
        .then(response=>{
          console.log(response.data)
          this.setState({projects:response.data.data})
        })
        .catch(error=>{
          console.log("ALL projects ERROR")
          console.log(error.response)
        })
      }
    getLocations = () => {
    axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
    .then(response=>{
        console.log(response.data)
        this.setState({locations:response.data.data})
    })
    .catch(error=>{
        console.log("ALL locations ERROR")
        console.log(error.response)
    })
    }
    addProperty = () => {
        
        const {type,finishingType,deliveryStatus,location,category,imgs,imgsType,pdf,pdfType,
            project,price,priceType,pricePerMeter,durationType,installmentDuration,downPayment,voiceNote,voiceNoteType} = this.state
            
            if(category !==null && location !==null &&
            project !== null &&imgs !==null ){
                this.setState({waitResponse:true})
                message.loading('Wait..', 1.5)
                var data = new FormData()
                data.append('type',type)
                data.append('finishingType',JSON.stringify(finishingType))
                data.append('deliveryStatus',JSON.stringify(deliveryStatus))
                data.append('project',project)
                data.append('price',price)
                data.append('priceType',priceType)
                data.append('category',category)
                data.append('location',location)
                data.append('pricePerMeter',price)
                if(priceType === 'INSTALLMENT'){
                  data.append('downPayment',downPayment)
                  data.append('durationType',durationType)
                  data.append('installmentDuration',installmentDuration)
                }
                
                if(imgs != null && imgsType){
                  for(var i=0 ; i<= imgs.length-1 ; i++){
                    data.append(`imgs`,imgs[i] )
                  } 
                }
                if(pdf != null && pdfType ==="data"){
                  data.append(`pdf`,pdf[0] )
                }
                if(voiceNote != null && voiceNoteType ==="data"){
                    data.append(`voiceNote`,voiceNote)
                }
                console.log(Array.from(data))

                axios.post(`${BASE_END_POINT}properties`,data,{
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':`Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
                },
                })
                .then(response=>{
                    this.setState({
                        showDialog:true,messageType:'success'
                    })
                })
                .catch(error=>{
                    console.log(error.response)
                    if(error.response.data.errors){
                        this.setState({
                            showDialog:true,
                            messageType:'error',
                            details:error.response.data.errors.msg[0].msg
                        })
                    }else{
                    this.setState({showDialog:true,messageType:'error'})
                    }
                    this.setState({waitResponse:false,progress:50})
                })
            }
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.addProperty()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    fileChangedHandlerImgs = (event) => {
        let imgs =[];
        let imgsPreviewUrl = [];
        let imgsName = []
    

        for(var i=0 ; i<= event.target.files.length-1 ; i++){
            imgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
            imgsName.push(event.target.files[i].name)
        }
        imgs.push(event.target.files)
        this.setState({
            imgs: event.target.files,
            imgsType:'data',
            imgsPreviewUrl:imgsPreviewUrl,
            imgsName:imgsName
        });
    
    };
    removeFileImgs = (url) => {
        console.log(url);
        let arr = this.state.imgsPreviewUrl;
        let arr2 =  Array.from(this.state.imgs);

        var index = arr.indexOf(url);
        if (index !== -1) arr.splice(index, 1);
        if (index !== -1) arr2.splice(index, 1);
        this.setState({
            imgsPreviewUrl: arr,
            imgs:arr2,
        });
        if(arr2.length === 0){
            this.setState({
            imgs:null,
            imgsName:[]
            });
        }

    };
    fileChangedHandlerPdf = event => {
        console.log(event.target.files[0])
        let pdfName = []
        pdfName.push(event.target.files[0].name)
        let pdf = [];
        pdf.push(event.target.files[0])
        this.setState({
            pdf: pdf,
            pdfType:"data",
            pdfName:pdfName
        })
        console.log(this.state.pdf)
        
    }
    removeFilePdf = () => {
        this.setState({
            pdf:null,
            pdfType:"url",
            pdfName:[]
        });
    }
    fileChangedHandlerVoiceNote = event => {
        console.log(event.target.files[0])
        this.setState({
          voiceNote: event.target.files[0],
          voiceNoteType:"data"
        })
        document.addEventListener("DOMContentLoaded", function() {
            document.getElementById('audioFileInput').addEventListener('change', function(event) {
              var file = event.target.files[0];
              var audioPlayer = document.getElementById('audioPlayer');
              var audioSource = document.getElementById('audioSource');
              if (file) {
                  audioSource.src = URL.createObjectURL(file);
                  audioPlayer.load();
              }
            });
        });
        
    }
    removeFileVoiceNote = () => {
        this.setState({
          voiceNote:null,
          voiceNoteType:"url"
        });
    }
      
  
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('PROPERTIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        <MDBContainer> 
            {this.state.showDialog &&
                <ResultDialog type={this.state.messageType} message={this.state.messageType ==="success"?allStrings.done:allStrings.error} details = {this.state.details}/>
            }
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#233b5b',zIndex:9}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>contact_phone</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.addProperty}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                            {this.state.waitResponse&&
                                                <Progress percent={this.state.progress} status="active" showInfo={false} />
                                            }
                                            <MDBRow>
                                                {/* <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form33" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.pricePerMeter}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.pricePerMeter} type="number" min={0} id="form33" name="pricePerMeter" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol> */}
                                                <MDBCol md="12">
                                                    <div className="md-form">
                                                        <label htmlFor="form34" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.price} type="number" min={0} id="form34" name="price" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                                <MDBCol md="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}         
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.type}
                                                        value={this.state.type}
                                                        onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({type:value});
                                                            this.getProjects(value)
                                                        }} 
                                                    
                                                    >
                                                        <TreeNode value="RELATED-TO-COMPOUND" title={allStrings.relatedToCompound} key="RELATED-TO-COMPOUND" />
                                                        <TreeNode value="SEPARATED" title={allStrings.separated} key="SEPARATED" />                                                
                                                        
                                                    </TreeSelect>
                                                </MDBCol>
                                                <MDBCol md="6" sm="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.project}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={true}
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}
                                                        treeNodeFilterProp="title"   
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.project}
                                                        value={this.state.project}
                                                        showCheckedStrategy= {SHOW_PARENT}
                                                        onChange={(value) => {
                                                            console.log(value)
                                                            this.setState({project:value});
                                                        }} 
                                                    
                                                    >
                                                        {this.state.projects.map(val=>(
                                                            <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                        ))}
                                                    </TreeSelect>
                                                </MDBCol>
                                                
                                            </MDBRow>
                                            <MDBRow>
                                            {this.state.priceType ==="INSTALLMENT"?
                                            <MDBCol md="6" sm="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={true}
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.durationType}
                                                    value={this.state.durationType}
                                                    showCheckedStrategy= {SHOW_PARENT}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({durationType:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                                    <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            :<MDBCol md="6" sm="6"></MDBCol>
                                            }
                                            <MDBCol md="6" sm="6">
                                                <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.priceType}</label>
                                                <TreeSelect virtual={false}
                                                    showSearch={false} 
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.priceType}
                                                    value={this.state.priceType}
                                                    showCheckedStrategy= {SHOW_PARENT}
                                                    onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({priceType:value});
                                                    }} 
                                                
                                                >
                                                    <TreeNode value="INSTALLMENT" title={allStrings.installment} key="INSTALLMENT" />
                                                    <TreeNode value="CASH" title={allStrings.cash} key="CASH" />
                                                    
                                                </TreeSelect>
                                            </MDBCol>
                                            </MDBRow>
                                            {this.state.priceType ==="INSTALLMENT"&&
                                            <MDBRow>
                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form323" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.downPayment}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.downPayment} type="text" id="form323" name="downPayment" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md="6">
                                                    <div className="md-form">
                                                        <label htmlFor="form324" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.installmentDuration}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.installmentDuration} type="number" min={0} id="form324" name="installmentDuration" className="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            }
                                            <MDBRow>
                                                <MDBCol md="6" sm="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.location}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={true}
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.location}
                                                        value={this.state.location}
                                                        showCheckedStrategy= {SHOW_PARENT}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                        this.setState({location:value});
                                                        }} 
                                                    
                                                    >
                                                        {this.state.locations.map(val=>(
                                                            <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                        ))}
                                                    </TreeSelect>
                                                </MDBCol>
                                                <MDBCol md="6" sm="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {false}         
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.category}
                                                        value={this.state.category}
                                                        showCheckedStrategy= {SHOW_PARENT}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                        this.setState({category:value});
                                                        }} 
                                                    
                                                    >
                                                        {this.state.categories.map(val=>(
                                                            <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                                        ))}
                                                    </TreeSelect>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                                
                                                <MDBCol md="6" sm="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.deliveryStatus}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {true}
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.deliveryStatus}
                                                        value={this.state.deliveryStatus}
                                                        showCheckedStrategy= {SHOW_PARENT}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                        this.setState({deliveryStatus:value});
                                                        }} 
                                                    
                                                    >
                                                        <TreeNode value="READY-To-MOVE" title={allStrings.readyToMove} key="READY-To-MOVE" />
                                                        <TreeNode value="AFTER-ONE-YEAR" title={allStrings.afterOneYears} key="AFTER-ONE-YEAR" />
                                                        <TreeNode value="AFTER-TWO-YEARS" title={allStrings.afterTwoYears} key="AFTER-TWO-YEARS" />
                                                        <TreeNode value="AFTER-THREE-YEARS" title={allStrings.afterThreeYears} key="AFTER-THREE-YEARS" />
                                                    
                                                    </TreeSelect>
                                                </MDBCol>
                                                <MDBCol md="6" sm="6">
                                                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.finishingType}</label>
                                                    <TreeSelect virtual={false}
                                                        showSearch={false} 
                                                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                        treeCheckable = {true}
                                                        treeNodeFilterProp="title"                      
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder={allStrings.finishingType}
                                                        value={this.state.finishingType}
                                                        showCheckedStrategy= {SHOW_PARENT}
                                                        onChange={(value) => {
                                                        console.log(value)
                                                        this.setState({finishingType:value});
                                                        }} 
                                                    >
                                                        <TreeNode value="FULLY-FINISHED" title={allStrings.fullyFinished} key="FULLY-FINISHED" />
                                                        <TreeNode value="SEMI-FINISHED" title={allStrings.semiFinished} key="SEMI-FINISHED" />
                                                        <TreeNode value="CORE-SHELL" title={allStrings.coreShell} key="CORE-SHELL" />
                                                        
                                                    </TreeSelect>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.files}</label>
                                                    <div className="fileUpload2"> 
                                                        <input  key={this.state.pdf} type='file' className="upImgs2" onChange={this.fileChangedHandlerPdf} ></input>
                                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                                    </div>
                                                    <div className="preview">
                                                        {this.state.pdfName.map((val) => (
                                                        <div style={{ display: "inline-block" }}>
                                                        <p className="previewPdf">{val}
                                                        <MDBIcon icon="times" className="mr-2 removePdf"
                                                            onClick={() => this.removeFilePdf()}  ></MDBIcon>
                                                        </p>
                                                        </div>
                                                        ))}
                                                    
                                                    </div>
                                                </MDBCol>
                                                <br></br>
                                                {this.state.pdf != null &&
                                                    <div>
                                                        <a href={this.state.pdf} target="blank" style={{background:'#233b5b',
                                                        color: '#fff',
                                                        padding: '3px 40px',
                                                        marginLeft: '15px'}}
                                                        >
                                                            {allStrings.openFile}          
                                                        </a>
                                                    </div>
                                                }
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.voiceNote}</label>
                                                <div className="fileUpload2"> 
                                                    <input  key={this.state.voiceNote} type='file' className="upImgs2" onChange={this.fileChangedHandlerVoiceNote} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                                </div>
                                                {this.state.voiceNote != null &&
                                                <div className="preview">
                                                  <audio controls className="audioPlayer" id="audioPlayer">
                                                      <source id="audioSource" src={URL.createObjectURL(this.state.voiceNote)} type="audio/mpeg"/>
                                                      <source src={URL.createObjectURL(this.state.voiceNote)}  type="audio/ogg"></source>
                                                        Your browser does not support the audio element.
                                                  </audio>
                                                    <MDBIcon icon="trash" className="mr-2 removeImg2" style={{top:'20px'}}
                                                        onClick={() => this.removeFileVoiceNote()}></MDBIcon>
                                                </div>
                                                }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.imgs}</label>

                                                    <div className="fileUpload2"> 
                                                        <input multiple key={this.state.imgs} type='file' className="upImgs2" onChange={this.fileChangedHandlerImgs} ></input>
                                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                    </div>
                                                    <div className="preview">
                                                    {this.state.imgsPreviewUrl.map((val,i) => (
                                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine',marginBottom:'10px'}}>
                                                        <img crossorigin="anonymous"alt="" src={this.state.imgsType==="data"?val:val} className="previewImg2"/>
                                                        
                                                        <span className="imgName">{this.state.imgsName[i]}</span>
                                                        <MDBIcon icon="trash" className="mr-2 removeImg2" 
                                                        onClick={() => this.removeFileImgs(val)}  ></MDBIcon>
                                                    </div>
                                                    ))}
                                                    </div>
                                                    
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                            <MDBBtn
                                            style={{background:'#233b5b',color:'#fff'}}
                                            rounded
                                            color="#233b5b"
                                            type="submit"
                                            className="btn-block z-depth-1a"
                                            >
                                            <MDBIcon icon="plus" className="mr-2" />
                                                {allStrings.add}          
                                            </MDBBtn>
                                          
                                        </div>
                                    </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Addproperty)
  );